import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ReturnData, TransmitData } from '../core/commnuication/communcaion-data';
import { CommuncationService } from '../core/commnuication/communcation.service';
import { Predefine } from '../core/contract/contract';
import { MerchantProfile } from '../models/merchant_profile';
import { map,tap,take, lastValueFrom, catchError, EMPTY  } from 'rxjs';
import { AuthModel } from '../models/colleague_model';
import { ToastService } from '../core/public/helper.service';
@Injectable({
  providedIn: 'root'
})
export class AuthFactory {

  merchant: MerchantProfile|null=null;

  auth: AuthModel | null= null;

  constructor(private http: HttpClient,private msg: ToastService) {  }

  merchantId=()=>localStorage.getItem('merchant_id')??'';

  staffId=()=>localStorage.getItem('staff_id')??'';

  async getCurrentMerchantAsync(id?: string,displayNotification =false ): Promise<MerchantProfile>{


    if(!!this.merchant){

      return this.merchant;
    }else{
      const thisId=id??this.merchantId();
      console.log(thisId);

      if(!!thisId){

        const data=new TransmitData('000000','EF0000',{id:thisId});
        const d$=this.http.post<ReturnData<MerchantProfile>>(`${Predefine.merchantHostUrl}/api/merchant/get/fromothers`, data)
        .pipe(
          map((one)=>one.content),

          tap(one=>{
            this.merchant=one;
            localStorage.setItem('merchant_id',one.merchantId);
          }),

          take(1),
          catchError((error: any)=>{

            if(displayNotification){
              this.msg.error(`${error.status}, ${error?.error?.message??error.statusText}`);
            }
            return EMPTY;
          }),
        );
        return lastValueFrom(d$);

      }
    }


  }

  async login(id: string, pws: string): Promise<AuthModel>{
    const params: Record<string,string>={id, pws};
    const merchant=await this.getCurrentMerchantAsync();
    const data=new TransmitData(merchant.merchantId,'EF0000',params);
    const d$=this.http.post<ReturnData<AuthModel>>(`${merchant.centreKitchenUrl}/api/colleague/get/jwtToken`, data)
    .pipe(
      map((one)=>one.content),

      tap(one=>{
        this.auth =one;
        this.setSession(one);
      }),
      take(1),
      catchError((error: any)=>{

        this.msg.error(`${error.status}, ${error?.error?.message??error.statusText}`);
        return EMPTY;
      }),

    );
    return lastValueFrom(d$);

  }

  public logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('staff_id');

  }
  public isLoggedIn(): boolean {

    return Date.now()<this.getExpiration();
  }

  private setSession(authResult: AuthModel){

    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('expires_at', authResult.expiresIn.toString() );
    localStorage.setItem('staff_id',authResult.userId);

  }
  private getExpiration(): number {
    const expiration = localStorage.getItem('expires_at');
    if(expiration){
        return +expiration;
    }else{
      return 0;
    }

   }
}
