import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/interrupt/jwt.interceptor';
import { StockqtyPipe } from './core/filters/stockqty.pipe';
import { TodayReceiptListWidgetComponent } from './assistant/today-receipt-list-widget/today-receipt-list-widget.component';
import { AssistantModule } from './core/assistant.module';
@NgModule({
  declarations: [AppComponent, LoginComponent,TodayReceiptListWidgetComponent],
  imports: [AssistantModule,BrowserModule,HttpClientModule , IonicModule.forRoot(), AppRoutingModule, FontAwesomeModule,FormsModule,],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent],

})
export class AppModule {}
