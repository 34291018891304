import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CommuncationService } from '../core/commnuication/communcation.service';
import { MultiQueryItem, StatisticsItem } from '../models/public_model';
import { StoreReceiptList } from '../models/store_model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private comm: CommuncationService) { }

    //新建一个收货单
    async newOneReceiptListAsync(data: Record<string,string>): Promise<StoreReceiptList>{

      return (await this.comm.withWarehouse<StoreReceiptList>(data,'store/accept/one')).content;
    }



    //获取一个商品的库存量
    async stockAmountAsync(stuffId: string): Promise<number>{

      return (await this.comm.withWarehouse<number>({stuffId},'InventoryBook/storeQty/byStuffId')).content;
    }


    async receiptListsOfOneDay(date: Date){

      const s=`${moment(date).format('yyyy-MM-DD')}`;
      const e=`${moment(date).add(1, 'days').format('yyyy-MM-DD')}`;

      const query: MultiQueryItem[]=[

        {queryTarget: 'DateRange', con1:s, con2:e, con3:''},
      ];

      const data: Record<string,string>={query:JSON.stringify(query),page:'1',entries:'500',
        listOrder:'Descending'};

      return (await this.comm.withWarehouse<Array<StoreReceiptList>>(data,'receiptList/query/paging')).content;

    }

      // 根据收货单，查询一个SKU一年内的各种进货价
  async getSkuProvideOneYearPrices(stuffId: string): Promise<StatisticsItem[]> {


    const data: Record<string,string>={stuffId,e:moment().add(1,'days').format('yyyy-MM-DD'),
              s:moment().subtract(1, 'years').format('yyyy-MM-DD')};

    return (await this.comm.withWarehouse<StatisticsItem[]>(data,'receiptList/prices/records')).content;
  }
}
