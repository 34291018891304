import { Component, OnInit } from '@angular/core';
import { SkuFactory } from './factory/suk-factory.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent  {
  constructor() {}


}
