import { Injectable } from '@angular/core';
import { CommuncationService } from '../core/commnuication/communcation.service';
import { ProviderProfile } from '../models/providers_model';

@Injectable({
  providedIn: 'root'
})
export class ProviderFactory{
  private providers: Array<ProviderProfile>=[];
  constructor(private comm: CommuncationService){}



  async listAllProvidersAsync(): Promise<ProviderProfile[]> {

    if(this.providers.length===0){
      this.providers=(await this.comm.withWarehouse<Array<ProviderProfile>>({},'providers/list/all')).content;
    }
    return this.providers;


  }


  async getOneAsync(id: string){
    const list=await this.listAllProvidersAsync();

    return list.find(one=>one.id===id);
  }
}
