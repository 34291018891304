import { Component, OnInit } from '@angular/core';
import { StoreReceiptList } from 'src/app/models/store_model';
import { StoreService } from 'src/app/service/store.service';

@Component({
  selector: 'app-today-receipt-list-widget',
  templateUrl: './today-receipt-list-widget.component.html',
  styleUrls: ['./today-receipt-list-widget.component.css']
})
export class TodayReceiptListWidgetComponent implements OnInit {
  list: Array<StoreReceiptList> = [];
  constructor(private store: StoreService) { }

  ngOnInit(): void {

    this.store.receiptListsOfOneDay(new Date()).then(r=>{
      this.list=r;
    });
  }


}
