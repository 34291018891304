//上传的标准化数据
export class TransmitData {

  appKey: string;
  timestamp: string;
  merchantId: string;
  random: string;
  staffId: string;
  from: string;
  token: string;
  actionParam: Record<string,string>;

  constructor(merchantId: string,staffId: string,actionParam: Record<string,string>){
    this.appKey='Zc315TPXv2';
    this.timestamp=Date.now().toString();
    this.merchantId=merchantId;
    this.random=(100000+Math.floor(Math.random() * 80000)).toString();
    this.staffId=staffId;
    this.from='BSS';
    this.token=''; //暂时不加签名

    this.actionParam=actionParam;

    for(const key in this.actionParam){

      if (!this.actionParam[key]){
        this.actionParam[key]='  ';
      }
    }


  }



}

//返回的标准话数据
export class ReturnData<T> {

  constructor(public content: T,public comments: string,done=true){}
}

//返回的分页数据
export class PaginationReturn<T>{
  constructor(public content: Array<T>,public totalRecord: number,public page: number,public totalPage: number,public entries: number){}
}

