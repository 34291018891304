import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, lastValueFrom, take } from 'rxjs';
import { AuthFactory } from 'src/app/factory/auth.service';

import { ToastService } from '../public/helper.service';
import { PaginationReturn, ReturnData, TransmitData } from './communcaion-data';

@Injectable({
  providedIn: 'root'
})
export class CommuncationService {

  constructor(private http: HttpClient,private msg: ToastService,private auth: AuthFactory) { }



  async withWarehouse<T>(actionParam: Record<string,string>,actionUrl: string){

    const staffId=this.auth.staffId(); //
    const merchant=await this.auth.getCurrentMerchantAsync();

    const data=new TransmitData(merchant.merchantId,staffId??'CK0000',actionParam);

    const hostUrl=merchant.centreKitchenUrl;


    // eslint-disable-next-line no-underscore-dangle
    const t$=this._withHost<T>(hostUrl,actionUrl,data).pipe(
      take(1),

      catchError((error)=>{

        this.msg.error(`服务器/网络错误，代码：${error.status}, ${error?.error?.message??error.statusText}`,);
        return EMPTY;


      })
    );


    return lastValueFrom(t$);

  }

  private _withHost<T>(hostUrl: string,actionUrl: string,postData: TransmitData ){

    return this.http.post<ReturnData<T>>(`${hostUrl}/api/${actionUrl}`,postData);

  }
  private _withHostList<T>(hostUrl: string,actionUrl: string,postData: TransmitData ){

    return this.http.post<PaginationReturn<T>>(`${hostUrl}/api/${actionUrl}`,postData);

  }

}
