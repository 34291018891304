import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFactory } from '../factory/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  staffId='';
  merchantId='';

  pws='';

  loading=false;

  constructor(private auth: AuthFactory,private router: Router) { }

  ngOnInit(): void {
  }

  submitDisabled=()=>!(this.merchantId&&this.staffId&&this.pws);

  async login(){

    this.loading=true;
    this.auth.getCurrentMerchantAsync(this.merchantId,true).then(m=>{

       this.auth.login('CK'+this.staffId,this.pws).then(s=>{
        this.loading=false;
        this.router.navigateByUrl('/');
       },err=>this.loading=false);
    },e=>this.loading=false);
  }

}
