import { Pipe, PipeTransform } from '@angular/core';
import { ProviderFactory } from 'src/app/factory/provider-factory.service';
import { StoreService } from 'src/app/service/store.service';

@Pipe({
  name: 'stockqty'
})
export class StockqtyPipe implements PipeTransform {

  constructor(private store: StoreService){}
  async transform(staffid: string): Promise<number>  {
    if(staffid){
      return await this.store.stockAmountAsync(staffid);
    }else{
      return 0;
    }

  }

}

@Pipe({
  name: 'providerName'
})
export class ProviderNamePipe implements PipeTransform {

  constructor(private provider: ProviderFactory){}
  async transform(id: string): Promise<string>  {
    if(id){
      return (await this.provider.getOneAsync(id)).name;
    }else{
      return '';
    }

  }

}

@Pipe({
  name: 'stuffPricesHistory'
})
export class StuffPricesHistoryPipe implements PipeTransform {

  constructor(private store: StoreService){}
  async transform(id: string): Promise<string>  {
    if(id){
      const ps=await this.store.getSkuProvideOneYearPrices(id);
      let re='';

      ps.forEach(one=>{
        re+=`${one.comments}:${(one.total/100).toFixed(1)} | `;
      });

      return re;

    }else{
      return '';
    }

  }

}
