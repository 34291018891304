export class Predefine {
  static merchantHostUrl = 'https://mms.api.poszan.com';

  static staffRoles: Record<string,string>={
    manager : '经理',
    warehouseKeeper:'库管',
    receiver:'收货',
    distributor:'发货',
    canteenStaff:'門店員工'
  };

  static expMode: Record<string,string>= //过期模式
  {
      product:'普通商品，廠家確定', //产品决定

      fresh:'生鮮，立刻',//生鲜，需要立刻

      long:'長期',//长期，一般是自定义的干货。

      short:'短期，盡快',//短期，尽快

  };

  static inventoryOperationMode: Record<string,string>={
    warehousing:'收貨',
    outStock:'發貨',
    damaged:'報損',
    unpacking:'拆包'

  };


}

export enum StaffRole{
  admin,
  manager,
  warehouseKeeper,
  receiver,
  distributor,
  portalApi, //,
  canteenStaff
}

export enum ExpMode //过期模式
{
    product, //产品决定

    fresh,//生鲜，需要立刻

    long,//长期，一般是自定义的干货。

    short,//短期，尽快

}


export enum StuffType //产品类型
{
    product,//成品

    semi,//半成品

    material,//原材料


}


