import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async success(msg: string,duration: number=2000) {
    const toast = await this.toastController.create({
      message: `完成：${msg}`,
      duration,
      position: 'bottom',
      cssClass: 'custom-toast',
      icon: 'happy-outline'
    });

    await toast.present();
  }
  async error(msg: string,duration: number=3000) {
    const toast = await this.toastController.create({
      message: `抱歉 ${msg}`,
      duration,
      cssClass: 'custom-toast',
      position: 'bottom',
      icon: 'sad-outline',
      animated:true,
      color: 'primary'
    });

    await toast.present();
  }
}
